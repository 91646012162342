import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Element } from "react-scroll"
import { GatsbyImage } from "gatsby-plugin-image"
import axios from "axios"
import loadable from "@loadable/component"

import PaperPlanIcon from "../../../assets/icons/paper-plane.svg"
import CheckCircle from "../../../assets/icons/check_circle.svg"
import PhoneIcon from "../../../assets/icons/phone.svg"
import MailIcon from "../../../assets/icons/mail.svg"
import PinIcon from "../../../assets/icons/location-pin.svg"

const HCaptcha = loadable(() => import("@hcaptcha/react-hcaptcha"))

class Contact extends Component {
  state = {
    formData: {
      message: "",
      name: "",
      email: "",
      interest: "",
      attachment: {
        filename: "Choose file...",
        type: "",
      },
    },
    fileEvent: null,
    errors: {},
    formSubmitting: false,
    formSubmitted: false,
    hCaptcha: {
      token: "",
    },
  }

  changeHandler = (e, formField) => {
    let formData = { ...this.state.formData, [formField]: e.target.value }
    this.setState({ formData })
  }

  fileChangeHandler = (e) => {
    if (e.target.files[0]) {
      let fileEvent = e.target.files[0]
      let attachment = {
        ...this.state.formData.attachment,
        filename: fileEvent.name,
        type: fileEvent.type,
      }
      let formData = { ...this.state.formData, attachment }
      this.setState({ formData, fileEvent })
    } else {
      let attachment = {
        ...this.state.formData.attachment,
        filename: "Choose file...",
        type: "",
      }
      let formData = { ...this.state.formData, attachment }
      this.setState({ formData, fileEvent: null })
    }
  }

  hCaptchaSuccess = (token) => {
    let newToken = { ...this.state.hCaptcha, token }
    this.setState({ hCaptcha: newToken })
  }

  onSubmitButton = async (e) => {
    e.preventDefault()
    this.setState({ formSubmitting: true, errors: {} })

    const formData = new FormData()
    formData.append("message", this.state.formData.message)
    formData.append("name", this.state.formData.name)
    formData.append("email", this.state.formData.email)
    formData.append("interest", this.state.formData.interest)
    formData.append("attachment", this.state.fileEvent)

    try {
      await axios.post(
        "https://www.tacticapps.com/api/hCaptcha",
        this.state.hCaptcha
      )
      await axios.post("https://www.tacticapps.com/api/contactForm", formData)
      this.setState({ formSubmitting: false, formSubmitted: true })
    } catch (err) {
      if (err.response) {
        this.setState({ errors: err.response.data, formSubmitting: false })
      } else {
        this.setState({
          errors: {
            server:
              "Our mail server has encountered an error locally. Please contact us directly.",
          },
          formSubmitting: false,
        })
      }
    }
  }

  render() {
    let sendButton

    if (this.state.formSubmitting) {
      sendButton = (
        <>
          <div className="formSpinner">
            <div />
            <div />
            <div />
            <div />
          </div>
        </>
      )
    } else if (!this.state.formSubmitting && this.state.formSubmitted) {
      sendButton = (
        <div className="text-primary">
          <CheckCircle
            style={{
              width: "16px",
              height: "16px",
            }}
          />{" "}
          <span className="h6">
            Thanks. We'll get back to you within 24 hours.
          </span>
        </div>
      )
    } else {
      sendButton = (
        <>
          <div className="form-group">
            {this.state.formData.message && (
              <HCaptcha
                sitekey="cf98a5c0-be6e-40b4-b5b7-4ccb0bf7fda6"
                onVerify={(token) => this.hCaptchaSuccess(token)}
              />
            )}

            <div
              className={
                this.state.errors.hCaptcha ? "invalid-feedback d-block" : ""
              }
            >
              {this.state.errors.hCaptcha}
            </div>
          </div>
          <button
            className="btn btn-info"
            onClick={(e) => this.onSubmitButton(e)}
          >
            <span className="mr-2">Connect With Us</span>
            <PaperPlanIcon style={{ height: "18px", width: "18px" }} />
          </button>
        </>
      )
    }

    return (
      <StaticQuery
        query={graphql`
          {
            worldBackground: file(relativePath: { eq: "bg-world.png" }) {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        `}
        render={(data) => (
          <Element name="contact">
            <section id="index-contact">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-lg-9 m-auto text-center">
                    <h2 className="text-capitalize">{this.props.heading}</h2>
                    <p className=" text-muted fw-l">
                      Fill in the form below and tell us about your project.
                      Feel free to attach your NDA or any supporting
                      documentation. We’ll get back to you within 24 hours.
                    </p>
                  </div>
                </div>
              </div>
              <div className="position-relative py-3">
                <GatsbyImage
                  image={data.worldBackground.childImageSharp.gatsbyImageData}
                  alt=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 order-1 order-md-0 mb-5">
                      <div className="row no-gutters">
                        <div className="col-2 text-center">
                          <PhoneIcon
                            style={{ width: "32px", height: "32px" }}
                          />
                        </div>
                        <div className="col-10">
                          <h4 className="fw-l m-0">
                            <span className="text-primary">+1 </span>
                            346-268-7886
                          </h4>
                          <p className="text-muted">
                            Don't hesitate to contact us.
                          </p>
                        </div>
                        <div className="col-2 text-center">
                          <MailIcon style={{ width: "32px", height: "32px" }} />
                        </div>
                        <div className="col-10">
                          <h4 className="fw-l m-0">
                            <span className="text-primary">info</span>
                            @tacticapps.com
                          </h4>
                          <p className="text-muted">
                            Please complete the form or email us directly.
                          </p>
                        </div>
                        <div className="col-2 text-center">
                          <PinIcon style={{ width: "32px", height: "32px" }} />
                        </div>
                        <div className="col-10">
                          <h4 className="fw-l m-0">
                            <span className="text-primary">Headquarter </span>
                            Location
                          </h4>
                          <p className="text-muted">
                            Houston, TX
                            <br />
                            77433, USA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 order-0 order-md-1 mb-5">
                      <form>
                        <div className="form-group">
                          <textarea
                            cols="40"
                            rows="5"
                            className={
                              this.state.errors.message
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Tell us about your project*"
                            value={this.state.formData.message}
                            onChange={(e) => this.changeHandler(e, "message")}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors.message}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input
                              type="text"
                              className={
                                this.state.errors.name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Name*"
                              value={this.state.formData.name}
                              onChange={(e) => this.changeHandler(e, "name")}
                            />
                            <div className="invalid-feedback">
                              {this.state.errors.name}
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              type="email"
                              className={
                                this.state.errors.email
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Email*"
                              value={this.state.formData.email}
                              onChange={(e) => this.changeHandler(e, "email")}
                            />
                            <div className="invalid-feedback">
                              {this.state.errors.email}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <select
                            value={this.state.formData.interest}
                            onChange={(e) => this.changeHandler(e, "interest")}
                            className={
                              this.state.errors.interest
                                ? "custom-select is-invalid"
                                : "custom-select"
                            }
                            style={
                              this.state.formData.interest === ""
                                ? { color: "#adb5bd" }
                                : { color: "#191919" }
                            }
                          >
                            <option value="">I'm interested in...*</option>
                            <option value="Company Website">
                              Company Website
                            </option>
                            <option value="Landing Page">Landing Page</option>
                            <option value="MVP">MVP</option>
                            <option value="Web Application Development">
                              Web Application Development
                            </option>
                            <option value="Mobile Application Development">
                              Mobile Application Development
                            </option>
                          </select>
                          <div className="invalid-feedback">
                            {this.state.errors.interest}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className={
                                this.state.errors.file
                                  ? "custom-file-input is-invalid"
                                  : "custom-file-input"
                              }
                              accept="image/jpeg, image/png, text/plain, application/pdf, application/msword, .doc,.docx"
                              id="validatedCustomFile"
                              onChange={(e) => this.fileChangeHandler(e)}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="validatedCustomFile"
                              style={
                                this.state.formData.attachment.filename ===
                                "Choose file..."
                                  ? { color: "#adb5bd" }
                                  : { color: "#191919" }
                              }
                            >
                              {this.state.formData.attachment.filename}
                            </label>
                          </div>
                          <div
                            className={
                              this.state.errors.file
                                ? "invalid-feedback d-block"
                                : "invalid-feedback"
                            }
                          >
                            {this.state.errors.file}
                          </div>
                        </div>

                        {sendButton}
                        <div
                          className={
                            this.state.errors.server
                              ? "invalid-feedback d-block"
                              : "invalid-feedback"
                          }
                        >
                          {this.state.errors.server}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Element>
        )}
      />
    )
  }
}

export default Contact
